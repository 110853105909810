<template>
    <div>
        <div class="body-w100 d-flex justify-content-between" v-if="data_smr">
            <div class="col-12 col-md-6" style="padding-right:20px" v-if="data_smr.length > 0">
                <div class="container-title-graph">
                    <h3>Reason for request distribution</h3>
                    <p></p>
                </div>
                <v-chart v-if="smrs.loaded" :autoresize="true" class="chart" :option="smrs"></v-chart>
            </div>
            <div class="col-12 col-md-6" v-if="data_asmr.length > 0">
                <div class="container-title-graph">
                    <h3>ASMR over time</h3>
                    <p>including only PIC, first application and new indication, excluding biosimilar, generic, hybrid and similar drugs</p>
                </div>
                <v-chart v-if="asmrs.loaded" :autoresize="true" class="chart" :option="asmrs"></v-chart>
            </div>
        </div>
    </div>
</template>
    
<script>

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from "echarts/components";
import VChart from "vue-echarts";
import _ from 'lodash'

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
]);

export default {
    name: "TabExpertGraph",
    components: {
        VChart
    },
    data() {
        return {
            smrs: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    bottom: '3%',
                    data: [this.$t('conclusion.Recommended'), this.$t('conclusion.Recommended with limitations'), this.$t('conclusion.Not recommended'), this.$t('conclusion.Unable to recommend')]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    data: [],
                },
                series: [
                    {
                        name: this.$t('conclusion.Unable to recommend'),
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#792F89'
                        }
                    },
                    {
                        name: this.$t('conclusion.Not recommended'),
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#FF0102'
                        }
                    },
                    {
                        name: this.$t('conclusion.Recommended with limitations'),
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false,
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#ff6204'
                        }
                    },
                    {
                        name: this.$t('conclusion.Recommended'),
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#46ad6d'
                        }
                    }
                ],
                loaded: false
            },
            asmrs: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    bottom: '3%',
                    data: ['I', 'II', 'III', 'IV', 'V', 'n/a']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    data: [],
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'I',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#9befba'
                        }
                    },
                    {
                        name: 'II',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false,
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#3cea7c'
                        }
                    },
                    {
                        name: 'III',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#60d189'
                        }
                    },
                    {
                        name: 'IV',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#34aa60'
                        }
                    },
                    {
                        name: 'V',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#0a8738'
                        }
                    },
                    {
                        name: 'n/a',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#FF0102'
                        }
                    }
                ],
                loaded: false
            },
        }
    },
    methods: {
        setDataSMRChart(){
            if(this.data_smr && !_.isEmpty(this.data_smr)){
                this.smrs.loaded = true
                this.smrs.series.forEach(serie => {
                    serie.data = []
                    let i = 0
                    while (i < Object.keys(this.data_smr).length) {
                        serie.data.push(0)
                        i++
                    }
                })

                for (const [demande, item] of Object.entries(this.data_smr)) {
                    this.smrs.yAxis.data.push(this.$t('transparence.trs_type_demandes.'+demande))
                    let index = this.smrs.yAxis.data.indexOf(this.$t('transparence.trs_type_demandes.'+demande))
                    for (const [evaluation, nb] of Object.entries(item)) {
                        this.smrs.series.find(serie => serie.name === this.$t('conclusion.'+evaluation)).data[index] = nb
                    }
                }
            }
        },
        setDataASMRChart(){
            if(this.data_asmr && !_.isEmpty(this.data_asmr)){
                this.asmrs.loaded = true
                this.asmrs.xAxis.data = []
                this.asmrs.series.forEach(serie => {
                    serie.data = []
                })

                this.asmrs.xAxis.data = Object.keys(this.data_asmr)
                this.asmrs.series.forEach(serie => {
                    let i = 0
                    while (i < Object.keys(this.data_asmr).length) {
                        serie.data.push(0)
                        i++
                    }
                })

                for (const [year, item] of Object.entries(this.data_asmr)) {
                    let index = this.asmrs.xAxis.data.indexOf(year)
                    for (const [asmr, nb] of Object.entries(item)) {
                        this.asmrs.series.find(serie => serie.name === asmr).data[index] = nb
                    }
                }
            }
        }
    },

    computed: {
        data_smr(){
            return this.$store.getters['result/smr_request'];
        },
        data_asmr(){
            return this.$store.getters['result/asmr_request'];
        },
    },

    mounted() {
        this.setDataSMRChart()
        this.setDataASMRChart()
    },

    watch: {
        data_smr: function() {
            this.setDataSMRChart()
        },
        data_asmr: function() {
            this.setDataASMRChart()
        }
    }
}
</script>
<style scoped>
    .chart {
        height: 400px;
        background: #fff;
        padding: 0 20px 20px;
        margin-bottom: 20px;
        min-width: 100%
    }
    .container-title-graph{
        background:#fff; 
        padding:25px 25px 0 25px;
        color:#000;
        height:95px;
    }
    .container-title-graph h3{
        font-size: 1.1rem;
        font-weight: 600;
    }
    .container-title-graph p{margin:0}
</style>