<template>

<div v-if="data">
    <div class="mb-4 w-100">
        <div class="mb-4" style="position: relative;">
            <div class="dropdown dropdown-btn" v-if="AgencyObj">
                <button class="btn dropdown-toggle border-light-gray dropdown-cs bg-white dropdown-search agency-dropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img class="fa-pull-left me-3" :alt="AgencyObj.agency_model" :src="require('@/assets/images/flag/new/'+AgencyObj.icon_flags+'.svg')" width="20" height="20" />
                    {{$t(AgencyObj.acronym)}}
                    <span><i class="fa fa-chevron-down"></i> </span>
                </button>
                <ul class="dropdown-menu dropdown-absolute overflow-auto" aria-labelledby="dropdownMenuButton1" v-if="listAgencyDropdown.length">
                    
                    <li v-for="(items, key) in listAgencyDropdown" :key="key">
                        <a class="dropdown-item" style="cursor: pointer; padding-left:0;" @click="setAgency(items.agency_model);">
                            <img class="fa-pull-left me-3" :alt="items.agency_model" :src="require('@/assets/images/flag/new/'+items.icon_flags+'.svg')" width="20" height="20" />
                            {{$t(items.acronym)}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
    <TabExpertGraph  v-if="this.$route.query.agency === 'Transparence' && isUserInter && !$route.query.zoom"/>
    <div class="body-w100">
        <div class="box-detail">
            <!-- <div class="wrapper-box-hta">
                <h1 class="fa-pull-left mr-4 mt-1">Reimbursement Status</h1>
                <div class="clearfix"></div>
            </div> -->
            <!---- Area Table Result Start-----> 
             <component v-if="componentAgency" :is="componentAgency"></component>
            <!---- Area Table Result End----->

                
        </div>
    </div>
        
</div>
<div v-else-if="!isLoad" class="body-w100" >
    <div class="box-detail">
        <div class="wrapper-box-hta">
            <h1 class="fa-pull-left mr-4 mt-1">{{$t('No Data')}}</h1>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
</template>

<script>
import { isUserInter } from '@/utils';
import TabExpertGraph from '@/components/result/tab_expert_graph.vue';
import { defineAsyncComponent } from "vue";

export default {
    name : 'TabExpert',
    components: {
        TabExpertGraph
    },
    methods: { 
        setAgency(agency){            
            this.$store.dispatch("result/setAgency", agency)   
            if(this.$route.query.agency !== agency){
                this.$router.push({ query: Object.assign({}, this.$route.query, {tab : 'expert', agency : agency }) });                   
            }
        },
    },
    updated(){
        console.log('tab_expert.vue updated')       
    },
    props : {
        listAgencyDropdown : {
            style : Array
        }
    },
    computed: {
        AgencyObj(){
            return this.$store.getters['result/AgencyObj'];
        },
        componentAgency(){
            if(this.AgencyObj && this.AgencyObj.agency_model){
                return defineAsyncComponent(
                    () => import(`@/components/result/agency/${this.AgencyObj.agency_model}.vue`)
                )
            } else{
                return ''
            }
        },
        listAgency(){
            return this.$store.getters['result/listAgency']
        },
        data(){
            return this.$store.getters['result/data'];
        },
        isLoad(){
            return this.$store.getters['result/load'] || this.$store.getters['result/load_agency'];
        },
        isUserInter
    },
    watch: {
        $route : function() {
            if(this.$route.name === "result"){ // check this page
                const Agency = this.$route.query.agency; 
                if(Agency && Agency !== ''){
                    this.setAgency(this.$route.query.agency)
                } else if(this.listAgencyDropdown.length){
                    this.setAgency(this.listAgencyDropdown[0].agency_model)
                } 
            }
            
        }
    },
}
</script>

<style>
.agency-dropdown{
    max-width : 350px;
    width: 65vw !important;
}
</style>
 
